<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <v-data-table
            v-if="data!=null && !api.isLoading "
            class="elevation-1"
            dense 
            :items="data"
            :search="search"
            :headers="dataHeader"
            >
            <template
                v-slot:top>
                <v-toolbar
                    width="auto"
                    height="auto"
                    class="py-3"
                    flat>
                    <v-row
                        no-gutters>
                        <v-col
                            class="mt-3 mx-3">
                            <v-row
                                class="mb-3">
                                <v-col>
                                    <v-toolbar-title
                                        class="font-weight-bold text-h4 my-3">
                                        Survey
                                    </v-toolbar-title>
                                </v-col>
                            </v-row>
                            <v-divider>
                            </v-divider>
                            <v-row class="mt-3">
                                <v-col>
                                    <v-text-field
                                        dense 
                                        outlined 
                                        clearable
                                        v-model="search"
                                        label="Search">

                                    </v-text-field>

                                </v-col>
                            </v-row>

                        </v-col>
                    </v-row>
                </v-toolbar>

            </template>
            <template
                v-slot:item.status="{item}">
                <span  
                    v-if="item.status=='1'">
                    Enabled
                </span>
                <span
                    v-else>
                    Disabled
                </span>
            </template>
            <template
                v-slot:item.testing="{item}">
                <span
                    v-if="item.testing=='1'">
                    Enabled
                </span>
                <span
                    v-else>
                    Disabled
                </span>
            </template>
            <template
                v-slot:item.index="{item}">
                <span>
                    {{ data.indexOf(item)+1 }}
                </span>
            </template>
            <template
                v-slot:item.type="{item}">
                <router-link :to="{name:'PageSurveySettingDetail',params:{id:item.survey_id}}">
                    {{ item.type }}
                </router-link>
            </template>
            <template
                v-slot:item.fname="{item}">
                <span>
                    {{ item.fname+" "+item.lname }}
                </span>
            </template>
            <template
                v-slot:item.start_at="{item}">
                <span>
                    {{ convertTimeZone(item.start_at) }}
                </span>
            </template>
            <template
                v-slot:item.end_at="{item}">
                <span>
                    {{ convertTimeZone(item.end_at) }}
                </span>
            </template>
        </v-data-table>
        
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
export default {
    components:{
    //
    },
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        search:"",
        data:null,
        dataHeader:[
            {
                text:'#',
                value:'index',
                align:'start',
            },
            {
                text:'Type',
                value:'type',
            },
            {
                text:'Status',
                value:"status",
            },
            {
                text:'Testing',
                value:'testing',
            },
            {
                text:'Tester',
                value:'fname',
            },
            {
                text:'Start date',
                value:'start_at',
            },
            {
                text:'End date',
                value:'end_at'
            }
        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="getAllSurveySettings"){
                this.data = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchSurveyListApi = this.fetchSurveyList();
            this.$api.fetch(fetchSurveyListApi);

        },
        fetchSurveyList(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            let url = process.env.VUE_APP_SERVER_API.replace('v1','v4');
            tempApi.url = url+"/survey/settings";
            return tempApi;
        },
       
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        convertTimeZone(time){
            return this.$moment(time).format('LLL')
        },
    }
}
</script>